/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

import useGlobalStorage from '../../modules/GlobalState';

function TransparentFooter() {

  const useStorage = useGlobalStorage({
    storageOptions: { name: 'accounts' }
  });

  const [token, setToken] = useStorage('token');
  
  return (
    <footer className="footer">
      <Container>
        <nav>
          <ul> 
            <li>
              <a
                href="/" 
              >
                Über uns
              </a>
            </li>  
              <li>
                <a
                  href="/faq-page"  
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;FAQ &amp; Features
                </a>
              </li> 
              { !token ? 
              <li>
                <a
                  href="/login-page"  
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Login
                </a>
              </li> 
              : <></>}
          </ul>
        </nav> 
      </Container>
    </footer>
  );
}

export default TransparentFooter;
