import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js"; 
import DefaultFooter from "components/Footers/DefaultFooter.js";
import Question from "components/Questions/Question.js";
import raw_questions from "assets/notes.js"; 
import FormHeader from "components/Headers/FormHeader.js";

function ArtistForm() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const parseFile = () => { 
      const lines = raw_questions.split('\n');
      var q = undefined;
      var a = undefined;
      var qs = [];
      for (var i = 0; i < lines.length; i++) {
          if (!lines[i] || lines[i][0] === '#') continue;

          console.log("LINE "+i, lines[i]);
          if (lines[i][0] >= '0' && lines[i][0] <= '9') {
            if (q && q.title && q.idx && q.type) { 
                qs.push(q);
            }
            q = {};
            a = [];
            var data = lines[i].split(',');
            var type = undefined;
            if (data[1].includes('M')) {
                type = "multiple_choice";
            } else if (data[1].includes('R')) {
                type = "radio_buttons";
            } else if (data[1].includes('F')) {
                type = "freetext";
            } else if (data[1].includes('N')) { 
                type = "number";
            } else if (data[1].includes('T')) { 
                type = "time";
            }
            if (type === undefined) {
                continue;
            }

            q.type = type;
            q.idx = data[0];
            q.required = data[1].includes('P');
            q.artist = data[1].includes('K');
            q.organizer = data[1].includes('V');
            q.answers = a;
          } else if (lines[i][0] === '-') {
              q.title = lines[i].substring(1);
          } else if (lines[i][0] === '=') {
            q.comment = lines[i].substring(1);
          } else if (lines[i][0] === ".") {
              a.push(lines[i].substring(1));
          } 
      }
      return qs;
  }; 
  const [questions, setQuestions] = React.useState(parseFile());
  const view = "artist";

  return (
    <>
      <FormHeader title="Künstler" />
      <ExamplesNavbar />
      <div className="wrapper"> 
        <div className="questions"> 
        { questions
            .filter((el) => {
                return (el.artist && view === "artist")
                || (el.organizer && view === "organizer");
            })
            .map((el, i) => (
                <Question id={i+1} data={el} />
            )) 
        }
        <Container className="question">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <Button>Abschicken</Button>
            </Col>
          </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ArtistForm;
