import React from "react";

// reactstrap components
import {
  Button,
  Input,
  Label,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container, 
  Row,
  Col,
} from "reactstrap"; 

function RadioButtons(props) {
  let pageHeader = React.createRef();  
  const answers = props.answers; 

  return (
    <>
        {answers.map(val => {
            return (
                <Row>
                  <FormGroup check>
                    <Col sm={12}> 
                      <div style={{textAlign:"left"}}>
                        <Label check>
                          <Input 
                            defaultValue="option1"
                            id="exampleRadios1"
                            name="exampleRadios"
                            type="radio" /> 
                          { val === "[]" ? <Input /> : val}
                        </Label>
                      </div>
                    </Col>
                  </FormGroup>
                </Row>
            );
        })}
    </>
  );
}

export default RadioButtons;
