import React from "react";

// reactstrap components
import {
  Button,
  Input,
  Label,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container, 
  Row,
  Col,
} from "reactstrap"; 

function MultipleChoice(props) {
  let pageHeader = React.createRef();  
  const answers = props.answers;
  const answersCut = [];
  for (var i = 0; i < answers.length; i+=2) {
    answersCut.push([answers[i], answers[i+1]]);
  }

  return (
    <>
        {answersCut.map(val => {
            return (
                <Row>
                    {val.filter(e => (e !== undefined)).map(val => {
                        return (
                            <Col sm={6}> 
                            <div style={{textAlign:"left"}}>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                { val === "[]" ? <Input /> : val}
                              </Label>
                            </FormGroup>
                            </div>
                            </Col>

                        )
                    })}
                </Row>
            );
        })}
    </>
  );
}

export default MultipleChoice;
