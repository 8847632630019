/*

=========================================================
* Now UI Kit React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages for this kit
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LoginPage from "views/examples/LoginPage.js";
import CallTheBoxPage from "views/examples/CallTheBoxPage.js";
import LandingPage from "views/examples/LandingPage.js";
import RegistrationPage from "views/examples/RegistrationPage.js";
import GuestsPage from "views/examples/GuestsPage.js";
import EventUserPage from "views/examples/EventUserPage.js";
import EventPage from "views/examples/EventPage.js";
import EventsOverview from "views/examples/EventsOverview.js";


import Form from "views/examples/Form.js";
import OrganizerForm from "views/examples/OrganizerForm.js";
import ArtistForm from "views/examples/ArtistForm.js";
import DiiEvalution from "views/examples/DiiEvaluation.js";
import ProfilePage from "views/examples/ProfilePage.js";
import FaqPage from "views/examples/FaqPage.js";

const profile_data = [
  /*
      ROB
  */
  {
    profileData: {
      name: "Rob",
      zip: 50911,
      city: "Cologne",
      type: "Beatmaker",
    },
    profileStats: {
      projects: 13,
      releases: 4,
      connections: 3,
    },
    design: {
      background: "music.jpg",
      profile: "profile-pic.jpg"
    },
    aboutMe: {
      title: "About me",
      description: "Ein Künstler von beträchtlicher Bandbreite, Rob - so der Name des in Melbourne aufgewachsenen und in Brooklyn ansässigen Robin der Wallach - schreibt, "
      + "spielt und nimmt seine gesamte Musik selbst auf und verleiht ihr ein warmes, "
      + "intimes Gefühl mit einer soliden Groove-Struktur. Ein Künstler von beträchtlicher Bandbreite."
    },
    projects: [
      {
        icon: "design_image",
        title: "CreativeName",
        video: "DiiBox.mp4",
      },
      {
        icon: "location_world",
        title: "Montagssession",
        background: "studio-1.jpg",
      },
      {
        icon: "sport_user-run",
        title: "At Diibox",
        background: "studio-2.jpg",
        track: "beat.mp3",
      }
    ],
    references: [
      {
        title: "Some company",
        image: "profile-pic.jpg",
        description: "Was fun",
      },
      {
        title: "Another Dude",
        image: "profile-pic.jpg",
        description: "Did this and that",
      },
      {
        title: "Yet Another Experience",
        image: "profile-pic.jpg",
        description: "Awesome thing",
      }
    ]
  },


  /*
      TRE
  */
  {
    profileData: {
      name: "Tre",
      zip: 50911,
      city: "Cologne",
      type: "Singer",
    },
    profileStats: {
      projects: 13,
      releases: 4,
      connections: 3,
    },
    design: {
      background: "tre-bgimg.jpg",
      profile: "tre-profile.jpg"
    },
    aboutMe: {
      title: "About me",
      description: "Ich bin Tre. Ich tu dir weh. Bööööh"
    },
    projects: [
      {
        icon: "design_image",
        title: "CreativeName",
        video: "DiiBox.mp4",
      },
      {
        icon: "location_world",
        title: "Montagssession",
        background: "studio-1.jpg",
      },
      {
        icon: "sport_user-run",
        title: "At Diibox",
        background: "studio-2.jpg",
        track: "beat.mp3",
      }
    ],
    references: [
      {
        title: "Some company",
        image: "profile-pic.jpg",
        description: "Was fun",
      },
      {
        title: "Another Dude",
        image: "profile-pic.jpg",
        description: "Did this and that",
      },
      {
        title: "Yet Another Experience",
        image: "profile-pic.jpg",
        description: "Awesome thing",
      }
    ]
  },


  /*
      MICHEE
  */
  {
    profileData: {
      name: "EdiMbellz",
      zip: 50939,
      city: "Cologne",
      type: "Producer/Rapper",
    },
    profileStats: {
      projects: 1,
      releases: 0,
      connections: 3,
    },
    design: {
      background: "michee-bgimg.jpg",
      profile: "michee-profile.jpg"
    },
    aboutMe: {
      title: "About me",
      description: "Edi Mbellz , 23, ein junger Produzent aus Erftstadt, einem Vorort von Köln. Der Einfluss eines Freundes und seinem Cousin inspirierten "
        +"ihn Musik zu machen. Doch ihn für die Musik zu begeistern schafften vorher schon Michael Jackson, Dr. Dre und 2 Pac. Daran angelehnt "
        +"baut er heute seine Beats."
    },
    projects: [
      {
        icon: "design_image",
        title: "CreativeName",
        video: "DiiBox.mp4",
      },
      {
        icon: "location_world",
        title: "Montagssession",
        background: "studio-1.jpg",
      },
      {
        icon: "sport_user-run",
        title: "At Diibox",
        background: "studio-2.jpg",
        track: "beat.mp3",
      }
    ],
    references: [
      {
        title: "Some company",
        image: "profile-pic.jpg",
        description: "Was fun",
      },
      {
        title: "Another Dude",
        image: "profile-pic.jpg",
        description: "Did this and that",
      },
      {
        title: "Yet Another Experience",
        image: "profile-pic.jpg",
        description: "Awesome thing",
      }
    ]
  }
]

const events = [
/*
  {
    path: "hejlove-aacc-2",
    id: 129387,
    name: "HEJLOVE & AACC #2",
    subname: "Vernissage",
    time: "21.08  7pm",
    place: "Große Brinkgasse 2b",
    description: "LINEUP\n\nAndreas Kappler-Gondolf (Kunstausstellung)\n\nAACC Merch Release\n\nAlkemie (live)\n\nCage (live)\n\nPascal Wagner\n\nTim Ferdinand\n\nMinoas Cirillo",
    logo: "aacclogo.svg",
    bgimg: "aaccbgimg2.jpg",
    //logo2: "aacclogo.svg",
    font: "Playsafe Display",
    capacityReached: false,
    instagram: "allartscollectivecologne",
    instagram2: "hejlove.cologne",
    //facebook: "link",
    youtube: "owhMW1hKCak", 
    youtube2: "ArAb7PrD6B0",
    navbarcolor: "#000000",
  }
*/
  {
    path: "thediivent",
    id: 129413,
    name: "Das Diivent",
    subname: "--",
    time: "11.12  8pm",
    place: "Großer Platz",
    description: "diibox feiert mit euch",
    logo: "logodb2.png",
    bgimg: "dbbgimg2.png",
    font: "Helvetica",
    capacityReached: false,
    navbarcolor: "#CC5500",

    //instagram: "allartscollectivecologne",
    //instagram2: "hejlove.cologne",
    //facebook: "link",
    //youtube: "owhMW1hKCak", 
    //youtube2: "ArAb7PrD6B0",
  }
];

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <LandingPage {...props} />} />
        <Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        />
        {
          profile_data.map(data => {
            return(
              <Route
                path={"/" + data.profileData.name.toLocaleLowerCase()}
                render={(props) => <ProfilePage {...data} />}
              />
            )
          })
        }

        <Route
          path="/form"
          render={(props) => <Form {...props} />}
        />
        <Route
          path="/form-organizer"
          render={(props) => <OrganizerForm {...props} />}
        />
        <Route
          path="/form-artist"
          render={(props) => <ArtistForm {...props} />}
        />
        <Route
          path="/form-diieval"
          render={(props) => <DiiEvalution {...props} />}
        />
        <Route
          path="/login-page"
          render={(props) => <LoginPage {...props} />}
        />
        <Route
          path="/faq-page"
          render={(props) => <FaqPage {...props} />}
        />
        <Route
          path="/call-the-box"
          render={(props) => <CallTheBoxPage {...props} />}
        />
        <Route
          path="/registration-page"
          render={(props) => <RegistrationPage {...props} />}
        />
        <Route
          path="/guests-page"
          render={(props) => <GuestsPage {...(events[0])} />}
        />
	<Route
          path="/eventData"
          render={(props) => <EventUserPage {...props} />}
        />
        <Route
                path="/eventsOverview"
                render={(props) => <EventsOverview {...props} />}
              />

        <Route
          path="/event-page"
          render={(props) => <EventPage {...props} />}
        />
        {
          events.map(data => {
            return(
              <Route
                path={"/" + data.path}
                render={(props) => <GuestsPage {...data} />}
              />
            )
          })
        }
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
