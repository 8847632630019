import React from "react";
import { Link } from "react-router-dom";
import useGlobalStorage from '../../modules/GlobalState';
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function ExamplesNavbar(data) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const history = useHistory();

  const useStorage = useGlobalStorage({
    storageOptions: { name: 'accounts' }
  });

  const [token, setToken] = useStorage('token');
  //const [events, setEvents] = useStorage('events');
  //const events = [{name: "Hejlove & AACC #2", ref: "/hejlove-aacc-2"}];
  const events = [{name: "Diivent", ref: "/thediivent"}];

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const navBarColor = data && data.backgroundcolor ? data.backgroundcolor : undefined;
  const style = {};
  if (navBarColor) {
    style["background-color"] = navBarColor;
  }

  console.log("create navbar with style: ", style);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color={style["background-color"] ? "" : "info"} style={style} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="/call-the-box"
              id="navbar-brand"
            >
              Call The Box
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Want to have a box close by? Vote for it with your zip code!
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              {token === undefined ?
                <>
                  <NavItem>
                    <NavLink to="/landing-page" tag={Link}>
                      Home
                    </NavLink>
                  </NavItem>
                  <hr />
                  {events.map((event) => {
                    return (
                      <NavItem>
                        <NavLink to={event.ref} tag={Link}>
                          {event.name}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                  {/*
              <NavItem>
                <NavLink href="/login-page">
                  Login
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/registration-page">
                  Sign up
                </NavLink>
              </NavItem>  
		          */}
                  {/* <NavItem>
                  <NavLink href="/guests-page">
                    Guest List
                  </NavLink>
                </NavItem>

                  <NavItem>
                    <NavLink href="/event-page">
                      Create an event
                    </NavLink>
                  </NavItem>
		            */}
                </>
                :
                <>
                  <NavItem>
                    <NavLink to="/index" tag={Link}>
                      Home
                    </NavLink>
                  </NavItem>
                  {/*
                  <NavItem>
                    <NavLink href="/rob">
                      Connections
                    </NavLink>
                  </NavItem>
                  */}
                  <hr />
                  <NavItem>
                    <NavLink href="/eventsOverview">
                      Events Overview
                    </NavLink>
                  </NavItem>
                  {/*}
                  <NavItem>
                    <NavLink to="/hejlove-aacc-2" tag={Link}>
                      Hejlove & AACC #2
                    </NavLink>
                  </NavItem>
                */}
                  <NavItem>
                    <NavLink to="/thediivent" tag={Link}>
                      Diivent
                    </NavLink>
                  </NavItem>
                  <hr />
                  <NavItem>
                    <NavLink href="#"
                      onClick={() => {
                        setToken(undefined);
                        history.push('/home');
                      }}
                    >
                      Logout
                    </NavLink>
                  </NavItem>
                </>}
              {/*
              <NavItem>
                <NavLink href="/form-organizer">
                  Veranstaltung erstellen
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/form-artist">
                  Für eine Veranstaltung bewerben
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/form-diieval">
                  DiiEvaluation
                </NavLink>
              </NavItem>
              */}
              {/*
              <NavItem>
                <NavLink
                  href="/"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Besuch uns auf Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  DiiBox on Instagram
                </UncontrolledTooltip>
              </NavItem>
		*/}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;
