import React from "react";

// reactstrap components
import Datetime from "react-datetime";
import {
  Button,
  Input,
  Label,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container, 
  Row,
  Col,
} from "reactstrap"; 

function Date(props) {
  let pageHeader = React.createRef();   

  return (
    <>
        <FormGroup>
        <Datetime
            timeFormat={true}
            inputProps={{ placeholder: "Date" }}
        />
        </FormGroup>
    </>
  );
}

export default Date;
