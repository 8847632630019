const raw_questions = `
#  Notes:
#  - Frage Titel
#  P Pflichtfrage
#  D Dropdown 
#  : Freitext Frage
#  M Multiple Choice Frage (wenn Zahl dahinter, limit für Antwortmöglichkeiten)
#  T Time Frage (Start und Ende)
#  [] Freitextfeld

###########################################
1,VPM3
-Welche Art von Veranstaltung planst Du?
.Musikveranstaltung
.Offene Bühne
.Session
.Vernissage
.Hochzeit
.Geburtstag
.Betriebsfeier
.Abiball
.Jubiläum
.Sonstiges 

2,VPT
-Wann soll die Veranstaltung stattfinden?

3,VPR
-Wo soll die Veranstaltung stattfinden?
=Standort
.[]
.Ich habe noch keine Location

4,VPN
-Wieviele Menschen sind eingeplant / haben Platz?

5,RVP,1.1
-Hast du bereits Künstler gebucht?
.Ja
.Nein

6,RVP,5.2
-Soll diibox weitere Künstler stellen?
.Ja
.Nein

7,MVP
-Welche Genres sollen gespielt werden?
.HipHop
.Blues
.Hardrock
.Folk
.R&B
.Drill
.Dancehall
.Rock
.Jazz
.Pop
.Techno
.[]

8,:VP
-Wann ist die Veranstaltung ein Erfolg für dich?
=Beispielsweise die Anzahl der Gäste oder Anderes…
.[]

#9,Struktur des Abends
#-Wie stellst du dir die grobe Struktur der Veranstaltung vor?
#.[]
#9.1, VT
#- Von - Bis ?
#9.2, VP
#-Ort?
#.[]

10,NVP
-Wieviele Quadratmeter hat das Areal?
.15-10000 

11,PVM
-Hast du bereits Personal im Bereich 
=Service, Licht- und Tontechnik, Security, DJ, Barkeeping, Reinigung, Aufbau&Abbau, Video,  etc. 
.[]


12,PV
-Welches Equipment steht bereits zur Verfügung ?
.Mikrofon
.Boxensystem
.Licht
.Mischpult
.DJ-Pult
.Mixer
.[]


13,PV
-Welches Equipment hättest du noch gerne ?
.[]


14,PV
-Stellst du Getränke?
.ja
.nein

15,PV
-Welche Art von Getränken?
.alkoholische Getränke
=Bier, Wein, Spirituosen
.nicht alkoholische Getränke
=Cola, Wasser , Mate, etc.

16,NPV
-Wie viel liter insgesamt ? 

17,RPV
-Stellst du Essen ?
.ja
.nein

18,MPV
.vegetarisch
.vegan
.mit Fleisch
.mit Fisch

19,RPV
-Soll sich diibox um Pre- und/oder Post Social Media Präsenz kümmern ?
.Pre
.Post
.Beides
.Nein

20,MPV
- Welche Atmosphäre wünschst du dir für deine Veranstaltung ?
=gemütlich, spektakulär, stilvoll, entspannt, unbemüht,
.[]

21,RPV
-Hast du bereits eine Veranstaltung über diiApp organisiert ?
.1.ja
.2.nein

22,NPV
-Wie viel Budget steht dir zur Verfügung ? 

23,VK
 -Was kann das diibox Team sonst noch für dich tun ?
.[]

#####################################################################

24,RKP
Hast du schon aufgenommene Songs ?
.1.ja
.2.nein

25,RKP
-Welche Genre bedienst du ?
.Blues
.Hardrock
.Folk
.R&B
.Drill
.Dancehall
.Rock
.Jazz
.Pop
.Techno
.Hiphop
.[]

26,RK
-Hast du bereits Bühnenerfahrung?
.1.Ja
.2.Nein

27,NKP
-Mit wievielen Menschen stehst du auf der Bühne?
.1-100

28,MKP
Mit wem genau ?
=Name, Username
.[]

29,KP
-Welches Equipment benötigst du für einen erfolgreichen Auftritt?
.[]

30,KPM
-Brauchst du Hilfe bei An-und/oder Abfahrt ?
.1.ja
.2.nein

31,K
Wo soll dich diiBox abholen?
.[]

32,K
Wo soll dich diiBox hin bringen?
.[]

33,K
Wie viel hast du dabei?
.[]

34,KMP
-Soll diibox dich für den Auftritt mit Personal unterstützen ? (DJ, Instrumente, weitere Stimmen etc.)
.ja 
.nein

35,KM
-Was für weiteres Personal benötigst du?
.DJ
.Instrumente
.Weitere Stimmen
.[]


36,KP
-Möchtest du den Auftritt aufnehmen?
.1.ja
.2.nein

37,KP
-Hast du Merch ?
.1.ja
.2.nein

38,K
-Sollen/können wir dich beim Verkauf unterstützen ?
.1.ja
.2.nein

39,K
-Welche Künstler würdest du für diese Veranstaltung empfehlen ?
=Username, Künstlername
.[]

40,K
-Welche deiner Freunde sollen wir auf die Gästeliste schreiben 
=Username
.[]

41,KNP
- Wie hoch ist deine Gage ? 
.0-10000

42,KP
-Ab wann ist dein Auftritt ein Erfolg für dich ?
.[]

43,K
-Was kann das diibox Team sonst noch für dich tun ?
.[]

`
export default raw_questions;
