const raw_questions = `
#diiEvaluation für Künstler
#Was ist das Ziel dieser Fragen? / Wurde das Ziel durch diese Fragen erreicht?

1,KPR
-Bist du neu bei diiBox?
.Ja
.Nein, ich bin schon diiBox User

13,KPR
-Warst du bei deiner Session alleine oder in einer Gruppe?
.Alleine
.In einer Gruppe

16,KPR
-Hast du bereits Erfahrung im Musik machen?
.Rookie
.Anfänger
.Amateur
.Halbprofi
.Profi

7,KN,1.1
-Wie viele Musiksessions hast du bereits bei uns besucht?

9,KN,1.1
-Wie viele Musiksessions hast du vor zu besuchen?

2,KPF
-Trage deine Postleitzahl (PLZ) ein
=Postleitzahl

3,KPM
-Welche Sprachen sprichst du?
.Deutsch
.Englisch
.Spanisch
.Französisch
.Italienisch
.Lingala
.Russisch
.Niederländisch
.Türkisch
.Arabisch
.[]

4,KPM
-Was ist dir besonders wichtig bei einer Session?
.Sound
.Qualität
.Schnelle Umsetzung
.[]

5,KPD,1.1.
-Mit welchem Genre verbindest du dein aktuelles Projekt am ehesten?
.HipHop
.Rap
.Rock
.Soul
.Blues
.Jazz
.Techno
.Dubstep
.Reggae
.R'n B
.Metal
.Klassik
.Funk
.Gospel
.Drum and Bass
.Indie
.Pop
.Reggaeton
.Trap
.Trash
.[]

6,KM
-Welches Genre möchtest du gerne in Zukunft mal ausprobieren ?
.HipHop
.Rap
.Rock
.Soul
.Blues
.Jazz
.Techno
.Dubstep
.Reggae
.R'n B
.Metal
.Klassik
.Funk
.Gospel
.Drum and Bass
.Indie
.Pop
.Reggaeton
.Trap
.Trash
.[]

9,KPR
-Wie produktiv warst du heute?
.gar nicht produktiv
.1
.2
.3
.4 
.Maximal produktiv

10,KR
-Was wünscht du dir für das nächste Mal?
.Mehr Produktivität
.Mehr Kreativität
.Mehr Effektivität
.Mehr Qualität

11,KPR
-Warst du im Flow?
.Nope
.1
.2
.3
.4 
.Voll drin!

12,KR
-Wie sehr wurden deine Erwartungen erfüllt?
.Gar nicht
.1
.2
.3
.4 
.Absolut erfüllt

14,KR,13.2
-Konntest du in der heutigen Session gut mit deiner Gruppe kommunizieren?
.Die Kommunikation war erschwert
.1
.2
.3 
.4
.Ja

17,KPR,13.2
-Hattest du das Gefühl Dich persönlich einbringen zu können?
.gar nicht
.1
.2
.3
.4 
.sehr

15,KPM,1
-Wie wohl hast du Dich während der Session gefühlt?
.Unwohl
.Mäßig
.Wohl
.Sehr wohl

18,KPR,13.2
-Hattest du das Gefühl Dich persönlich einbringen zu können?
.gar nicht
.1
.2
.3
.4 
.sehr

19,KP
-In welchem Stadium siehst du das aktuelle Projekt?
.Ideenfindung
.Abgeschlossen
.Ausarbeitung
.Feinschliff
.Texten
.Arrangieren
.[]

20,KDM
-Welche der folgenden Begriffe würdest Du mit dir und Deiner letzten Session in Verbindung bringen? Wähle bitte mindestens 5 von den folgenden aus (Du kannst auch ergänzende Adjektive nennen)
.produktiv
.ehrlich
.selbstbewusst
.beständig
.erfolgreich
.toleriert
.akzeptiert
.leidenschaftlich
.beteiligt
.verantwortungsbewusst
.wertgeschätzt
.bestätigt
.glücklich
.eigeninitiativ
.engagiert
.flowig
.verständnisvoll
.empathisch
.kreativ
.teamfähig
.[]

21,KPM3
-Was ist dein Ziel..?
.Nur Musik machen
.EP
.Single
.Album
.Konzerte geben
.Auf Veranstaltungen spielen/sprechen

31,KR,21.2.3.4.
-Was ist dir besonders wichtig bei der Songproduktion?
.Sound
.Qualität
.Schnelle Umsetzung
.Verfügbarkeit
.[]

31,K,21.2.3.4.
-Welche Tools brauchst du aufjedenfall bei einer Aufnahme?
. 

32,KF
Wie lange möchtest du dir im Schnitt Zeit nehmen um einen Song fertig zu stellen?

33,KPR
-Was sind längerfristige Ziele für dich in deiner musikalischen Entwicklung?
.theoretisches Wissen erlangen
.Qualität verbessern

22,KPR
-Kommst du wieder zu diiBox?
.Ja
.Nein
.Vielleicht

23,KR
-Falls Du wiederkommen willst, in welchen Abständen ungefähr?
.monatlich
.1 mal die Woche
.2 mal die Woche
.Mehr als 2 mal die Woche
.unregelmäßig

24,KPM3
-Zu welchen Tageszeiten?
.morgens
.mittags
.abends
.nachts

25,KPR
-Welche Sessiondauer ist ideal?
.1 Stunde
.2 Stunden
.3 Stunden
.4 Stunden
.mehr Stunden

26,KPF
-Wie viel würdest du für eine Session deiner angegebenen Dauer zahlen ? (auf vorherige Frage bezogen)

27,KPR
-Würdest du gerne Unterricht nehmen?
.Beats bauen
.Gesang
.Instrumente
.Texten
.Aufnahmeleitung

28,KPR
-Hattest Du das Gefühl unsere Kritik / unser Feedback war hilfreich?
.Ja
.1
.2
.3
.4 
.Nein

29,KPR
-Deine abschließende Gesamtbewertung für Deine Erfahrung bei diiBox
.Genial
.1
.2
.3
.4 
.Katastrophal

30,K
Willst du noch irgendwas loswerden? 

`
export default raw_questions;