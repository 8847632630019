import React from "react";
import axios from "axios";

// reactstrap components
import {
    Collapse,
    Radio,
    RadioButtons,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Label,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import useGlobalStorage from '../../modules/GlobalState';

import jsPDF from "jspdf";
import "jspdf-autotable";
 

function EventsOverview() {
    axios.defaults.baseURL = 'https://diibox.de/api/';

    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);
    React.useEffect(() => {
        document.body.classList.add("login-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("login-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    const useStorage = useGlobalStorage({
        storageOptions: { name: 'accounts' }
    });

    const [token, setToken] = useStorage('token');

    console.log("token is: ", token);
    const [view, setView] = React.useState(0);

    const [eventData, setEventData] = React.useState({});
    const [events, setEvents] = React.useState([]);
    const [eventDataSummary, setEventDataSummary] = React.useState({});

    const [message, setMessage] = React.useState("");

    const getEvent = async (token, eventid) => {
        try {
            const options = { headers: { "jwt-token": token }, params: { eventId: eventid, } };
            console.log("with options: ", options);
            const resp = await axios.get('guests/events', options);
            console.log("got data: ", resp.data);
            const result = resp.data || {};
            if (result.events) {
                setEvents(result.events);
                //query each events guestlist
                result.events.forEach((e) => {
                    getEvent(token, e.id)
                })
            } else {
                const newEventData = { ...eventData };
                newEventData[eventid] = result.guests;
                setEventData(newEventData);
                const impfung = result.guests.filter((v) => v.impfung);
                const test = result.guests.filter((v) => v.test);
                const genesen = result.guests.filter((v) => v.genesen);
                const visited = result.guests.filter((v) => v.visited);
                const newEventDataSummary = { ...eventDataSummary };
                newEventDataSummary[eventid] = { impfung: impfung.length, test: test.length, genesen: genesen.length, visited: visited.length };
                setEventDataSummary(newEventDataSummary);
                console.log("updated event data: ", newEventData, newEventDataSummary);
            }
        } catch (err) {
        }
    };

    const printEvent = async (id) => {

        /*
            Geschlecht
            2G/3G/2G+
            broadcasting
            Ausweis upload
            
            sterne feedback (bis zu) 3 fragen
            
            Random Dashboard (gekommen, gegangen, ausgegeben, konsum)

            
            
            Mindestverzehrkarte (ausgang)

        */

    }


    const exportPDF = (expEvent) => {  
        const expData = eventData[expEvent.id]; 
        
        console.log("EVE: ", expEvent);
        console.log("DAT: ", expData);

        const doc = new jsPDF("landscape",  "pt", "A4");
        doc.setFontSize(14); 

        const data = expData.map((e) => { 

            var date = new Date(e.arrived); 
            var hours = date.getHours(); 
            var minutes = "0" + date.getMinutes();
            var seconds = "0" + date.getSeconds(); 
            var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

            return [e.name, e.telephone, e.email, e.zip, e.impfung, e.test, e.genesen, formattedTime];
        }); 
    
        let content = {
          startY: 50,
          head:  [["NAME", "TELEFON", "EMAIL",  "PLZ", "GEIMPFT", "GETESTET", "GENESEN", "ANKUNFT"]],
          body: data
        };

        doc.text(expEvent.name, 40, 40);
        doc.autoTable(content);
        doc.save("report.pdf");
        
      }

    React.useEffect(() => {
        getEvent(token);
    }, [token]);
    

    return (
        <>

            <ExamplesNavbar />
            <div className="page-header clear-filter" filter-color="blue">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: "url(" + require("assets/img/future.jpeg") + ")",
                    }}
                ></div>
                <div className="content">
                    <Container>
                        <Col className="ml-auto mr-auto" md="4">
                            <Card className="card-login card-plain">
                                <Form action="" className="form" method="">
                                    <CardHeader className="text-center">
                                    </CardHeader>
                                    <CardBody>
                                        <h5>Übersicht über Deine Veranstalungen:</h5>

                                    </CardBody>
                                    <CardFooter className="text-center">
                                        {view == 0 || true || events.length < 2
                                            //show events overview
                                            ? <>
                                                <table style={{ width: "100%" }}>
                                                    {events.map((e) => {
                                                        return (
                                                            <>
                                                                <tr
                                                                    style={{ padding: "10px"}}
                                                                >
                                                                    <td><h6>{e.name}</h6></td>
                                                                    <td>                   
                                                                        <Button 
                                                                            block
                                                                            type="submit" 
                                                                            className="btn-round"
                                                                            color="info"
                                                                            onClick={(clickEvent) => { 
                                                                                clickEvent.preventDefault();
                                                                                    exportPDF(e); 
                                                                            }}  
                                                                        >
                                                                            Export PDF
                                                                        </Button>
                                                                    </td> 
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{"padding-top": "20px"}}
                                                                        colspan="2"
                                                                    > 
                                                                        {true || events.length < 2 ?
                                                                            <>
                                                                                <h6>Gäste:  {(eventData[e.id] || []).length}</h6>
                                                                                <table style={{ width: "100%" }}>
                                                                                    <tr><td>Name</td><td>Impf.</td><td>Test</td><td>Gen.</td><td>Anw.</td></tr>
                                                                                    <tr><td></td>
                                                                                        <td>{(eventDataSummary[e.id] ? eventDataSummary[e.id].impfung : "-")}</td>
                                                                                        <td>{(eventDataSummary[e.id] ? eventDataSummary[e.id].test : "-")}</td>
                                                                                        <td>{(eventDataSummary[e.id] ? eventDataSummary[e.id].genesen : "-")}</td>
                                                                                        <td>{(eventDataSummary[e.id] ? eventDataSummary[e.id].visited : "-")}</td>
                                                                                    </tr>
                                                                                    <tr style={{ height: "10px" }}></tr>
                                                                                    {console.log(e, eventData[e.id])}
                                                                                    {(eventData[e.id] || []).map((g) => {
                                                                                        return (<tr>
                                                                                            <td
                                                                                            >
                                                                                                {g.name}
                                                                                            </td>
                                                                                            <td
                                                                                            >
                                                                                                {g.impfung ? "JA" : "-"}
                                                                                            </td>
                                                                                            <td
                                                                                            >
                                                                                                {g.test ? "JA" : "-"}
                                                                                            </td>
                                                                                            <td
                                                                                            >
                                                                                                {g.genesen ? "JA" : "-"}
                                                                                            </td>
                                                                                            <td
                                                                                            >
                                                                                                {g.visited ? "JA" : "-"}
                                                                                            </td>
                                                                                        </tr>);
                                                                                    })}
                                                                                </table></>
                                                                            : <></>}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2" style={{"padding-top": "25px"}}>
                                                                        Nachricht an alle Gäste senden
                                                                        <textarea
                                                                            style={{width: "100%",
                                                                                    "margin-top": "5px"}}
                                                                             onChange={(e) => {
                                                                                 console.log("settings message: ", e);
                                                                                setMessage(e.target.value);
                                                                             }}   
                                                                        >

                                                                        </textarea>
                                                                        <button
                                                                            onClick={(clickEvent) => {
                                                                                clickEvent.preventDefault();
                                                                                   
                                                                                const options = { headers: { "jwt-token": token }, params: { eventId: e.id, message: message} };
                                                                                axios
                                                                                    .get('guests/broadcast', options)
                                                                                    .then((res) => {
                                                                                        console.log("RES: ", res);
                                                                                    }, (err) => {
                                                                                        console.log("ERR: ", err);
                                                                                        alert("error occured: "+err);
                                                                                    });
                                                                            }}
                                                                        >
                                                                            Broadcasten
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        );
                                                    })}
                                                </table>
                                            </>
                                            :
                                            //show specific event
                                            <>


                                            </>}

                                    </CardFooter>
                                </Form>
                            </Card>
                        </Col>
                    </Container>
                </div>
                <TransparentFooter />
            </div>
        </>
    );
}

export default EventsOverview;
