import React from "react";
import axios from "axios";

// reactstrap components
import {
  Radio,
  RadioButtons,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

import { useLocation } from "react-router-dom";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";

function EventUserPage() {
  axios.defaults.baseURL = 'https://diibox.de/api/';

  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const query = new URLSearchParams(useLocation().search);
  //const data = {name: "Test"};

  const [data, setData] = React.useState({});
  const getData = async () => {
    try {
      const resp = await axios.get('guests/get', { params: { eventId: query.get("eventId"), dataId: query.get("dataId") } });
      console.log("got data: ", resp.data);
      setData(resp.data || {});
    } catch (err) {
      // Handle Error Here
    }
  };
  React.useEffect(() => {
    getData();
  }, []);

  const [password, setPassword] = React.useState("");

  const handleChange = (e) => {
    setPassword(e.target.value);
  }

  const [successMsg, setSuccessMsg] = React.useState(undefined);

  const onFormSubmit = () => {
    const submitData = {
      password: password,
      eventId: query.get("eventId"),
      dataId: query.get("dataId"),
    };
    console.log("submitting: ", submitData);

    axios
      .post('guests/visitor', submitData)
      .then((res) => {
        console.log("RES: ", res);
        if (res.data.success) {
          setSuccessMsg("Gast wurde als anwesend markiert");
          console.log("SUCCESS!");
        } else {
          alert("error: " + res.data.error);
        }
      }, (err) => {
        alert("error occured");
      });
  };

  return (
    <>

      <ExamplesNavbar />
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/future.jpeg") + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form className="form" >
                  <CardHeader className="text-center">
                    <div className="big-logo-container">

                    </div>
                  </CardHeader>
                  <CardBody>
                    <h3>Besucher Daten:</h3>
                    EventID: {query.get("eventId")}<br />
                    DataID: {query.get("dataId")}
                    <div style={{ margin: "10px" }}>
                      <table>
                        <tr><td style={{ "text-align": "left" }}>Name:</td><td style={{ "padding-left": "10px", "text-align": "left" }}>{data.name}</td></tr>
                        <tr><td style={{ "text-align": "left" }}>E-Mail:</td><td style={{ "padding-left": "10px", "text-align": "left" }}>{data.email}</td></tr>
                        <tr><td style={{ "text-align": "left" }}>Telefon:</td><td style={{ "padding-left": "10px", "text-align": "left" }}>{data.telephone}</td></tr>
                        <tr><td style={{ "text-align": "left" }}>Straße:</td><td style={{ "padding-left": "10px", "text-align": "left" }}>{data.street}</td></tr>
                        <tr><td style={{ "text-align": "left" }}>PLZ & Ort:</td><td style={{ "padding-left": "10px", "text-align": "left" }}>{data.zip} {data.city}</td></tr>
                        <tr><td style={{ "text-align": "left" }}></td><td style={{ "padding-left": "10px", "text-align": "left", "padding-top": "10px" }}></td></tr>
                        <tr><td style={{ "text-align": "left" }}>Geimpft:</td><td style={{ "padding-left": "10px", "text-align": "left" }}>{data.proof && data.proof.impfung ? "ja" : "-"}</td></tr>
                        <tr><td style={{ "text-align": "left" }}>Getestet:</td><td style={{ "padding-left": "10px", "text-align": "left" }}>{data.proof && data.proof.test ? "ja" : "-"}</td></tr>
                        <tr><td style={{ "text-align": "left" }}>Genesen:</td><td style={{ "padding-left": "10px", "text-align": "left" }}>{data.proof && data.proof.genesen ? "ja" : "-"}</td></tr>
                      </table>

                    </div>

                  </CardBody>
                  <CardFooter className="text-center">
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >



                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        disabled={false}
                        placeholder=""
                        type="password"
                        name="password"
                        style={{ "fontFamily": "arial", "fontSize": "0.95em" }}

                        onChange={(e) => handleChange(e, "name")}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                      ></Input>
                    </InputGroup>
                    <Button
                      block
                      className="btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        onFormSubmit();
                      }}
                      size="lg"
                    >
                      Als tatsächlich erschienen markieren
                    </Button>
                    {successMsg ? <>{successMsg}</> : <></>}

                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default EventUserPage;
