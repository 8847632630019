import React from "react";
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar backgroundcolor="black" />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
 		
                  <h2 className="title">
                    diibox
                  </h2>
                  <p>
                  Hier ergibt sich die Chance
  deine Kunst zu teilen, zu hören oder zu bearbeiten.
  Du
  wirst mit weiteren Künstler*innen Gemeinsamkeiten und Erfahrungen austauschen, aber auch Projekte teilen
  oder Mitarbeit an Projekten oder Ideen anderer Künstler*innen anfragen können.<br/><br/>


  Als
  Kreativschaffende*r kannst Du mithilfe unserer Matching-Funktion
  sowohl lokale, als auch globale Kontakte knüpfen oder Dich einfach
  inspirieren lassen.
                  </p>


              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/container2.jpg") + ")",
			//backgroundColor: "white",
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "diibox
hilft künstlerische Fähigkeiten zu erkennen und zu
fördern, sowohl bei erfahrenen, als auch newcomer Künstlern." <br></br>
                      <br></br>
                      <small>-ROB #50933</small>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/p1.jpg") + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bild2.jpg") + ")",
                    }}
                  ></div>
                  


                  <br />
		
                <h3 className="title">Connect</h3>
                <h5 className="description">
                Über Connect bist du in der
Lage, direkt auf dem Künstlerprofil ganz transparent
nachzuvollziehen, wie die jeweiligen Künstler*innen miteinander verbunden sind und
welche Werke diese bereits veröffentlicht haben bzw woran sie gerade arbeiten.<br/>
Der Austausch zwischen den Künstlern*innen ist diibox sehr wichtig,
deswegen kannst du andere Künstlern*innen direkt über die Chat-Funktion anschreiben.
                </h5>


                </Col>
              </Row>
            </div>

            <div className="separator separator-primary"></div>
            <Row>
{/*
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Buchen</h2>
                <h5 className="description">
Bei uns kannst du Musikstudios und Proberäume in sehr guter Qualität buchen.
Dafür haben wir Schiffcontainer umgebaut und mit einem gehobenen Standard an Euquipment ausgestattet.<br /><br />

Zusätzlich bieten wir in unseren Räumlichkeiten eine einzigartige Art und Weise Musik zu gestalten und zu kreiieren. Stichtwort: VR<br /><br />

Wir haben es geschafft ein Intuitives VR-Musik-Programm
zu entwickeln mit dem alle unsere Kunden ein individuelles Gemälde aus Sound und Farbe herstellen können.<br /><br />

Als normaler user kannst du bpsw auf unserer Seite direkt nach einer Session deine Fortschritte aufrufen kannst und dich mit anderen Musiker connecten.<br />
Als Premium-User für 3,99€ im Monat, bekommst du Pushnachrichten mit Sonderpreisen, hast vollen Zugriff auf die Oyoki-Library und kannst zudem als DiiBox-Musiker auftreten.  <br /><br />

Wenn kein Studio in deiner Nähe ist, geh auf CallTheBox und sorg mit deiner Stimme für ein zukünftiges Zusammen treffen. <br /><br />

Viel Spaß !
                </h5>

              </Col>
*/}
            </Row>


          </Container>

        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
