import React from "react";
import MultipleChoice from "components/Questions/MultipleChoice";
import Freetext from "components/Questions/Freetext";
import RadioButtons from "components/Questions/RadioButtons";
import Date from "components/Questions/Date";
import Number from "components/Questions/Number";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap"; 

function Question(props) {
  let pageHeader = React.createRef(); 
  const id = props.id; 
  const title = props.data.title;
  const type = props.data.type;
  const comment = props.data.comment;
  const answers = props.data.answers;

  return (
    <>
    <Container className="question">
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h3 className="title">{id}. {title}</h3>
          {comment === undefined ? "" : 
            <h5 className="description">
            { comment }
            </h5>
          }
        </Col>
      </Row>
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <Container>
          {type === "freetext" ?  
            <Freetext />
          : type === "time" ?  
            <Date />
          : type === "number" ?  
            <Number />
          : type === "radio_buttons" ?  
            <RadioButtons answers={answers} />
          : type === "multiple_choice" && answers ?
            <MultipleChoice answers={answers} />
          : ""}
          </Container>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Question;
