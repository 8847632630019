import React from "react";
import CounterInput from "react-bootstrap-counter";

// reactstrap components
import {
  Button,
  Input,
  Label,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,  
  Row,
  Col,
} from "reactstrap"; 

function Number(props) {
  let pageHeader = React.createRef();   

  return (
    <>
        <CounterInput 
            max={100} 
            min={10} 
            onChange={ (value) => { console.log(value) } } 
            value={5} />
    </>
  );
}

export default Number;
