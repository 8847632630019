import React from "react";
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function ProfilePage(data) {

  const [pills, setPills] = React.useState("2");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader data={data.profileData} stats={data.profileStats} design={data.design} />
        <div className="section">
          <Container>
            <div className="button-container">
              <Button className="btn-round" color="info" size="lg">
                Connecten
              </Button>
              <Button
                className="btn-round btn-icon"
                color="default"
                id="tooltip340339231"
                size="lg"
              >
                <i className="fab fa-message"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip340339231">
                Follow me on Instagram
              </UncontrolledTooltip>
            </div>

            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <h3 className="title">{data.aboutMe.title}</h3>
                <h5 className="description" style={{color: "#eeeeee", "font-style": "italic"}}>
                  {data.aboutMe.description}
                </h5>
              </Col>
            </Row>

            <Row>
              <Col className="ml-auto mr-auto" md="6">
              <h3 className="title">Projects</h3>
                <div className="nav-align-center">
                  <Nav
                    className="nav-pills-info nav-pills-just-icons"
                    pills
                    role="tablist"
                  >
                    {data.projects.map((project, i) =>
                      <NavItem>
                        <NavLink
                          className={pills === ""+(i+1) ? "active" : ""}
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setPills(""+(i+1));
                          }}
                        >
                          <i className={"now-ui-icons "+project.icon}></i>
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                </div>
              </Col>
              <TabContent className="gallery" activeTab={"pills" + pills}>
                {data.projects.map((project, i) =>
                  <TabPane tabId={"pills"+(i+1)}>
                    <Col className="ml-auto mr-auto" md="10">
                      <Row className="collections">
                        <Col md="12">
                          {project.video
                          ? <div
                            style={{"max-width": "100%"}}
                          >
                              <div
                                //style={{position: "relative", "padding-top": "20.25%", width: "100%"}}
                              >
                                <ReactPlayer
                                  style={{position: "relative", top: 0, left: 0, width: "100%", height: "100%"}}
                                  controls
                                  width="100%"
                                  height="100%"
                                  url={require("assets/videos/"+project.video)}
                                />
                              </div>
                            </div>
                          : <><img
                              alt="..."
                              className="img-raised"
                              style={{filter: "brightness(70%)"}}
                              src={require("assets/img/"+project.background)}
                            >
                            </img>
                                {project.track ? <>
                                <ReactAudioPlayer
                                  //src={"assets/sounds/"+project.track}
                                  src={require("assets/sounds/"+project.track)}
                                  controls
                                  style={{width: "100%", margin: 0, position: "absolute", bottom: 30, left: 0, padding: 0, "padding-left": 15, "padding-right": 15}}
                                />
                              </> : <></>}

                              <h3 style={{
                                "font-size": 32,
                                "text-shadow": "0px 0px 50px #000000, 0px 0px 10px #000000, 0px 0px 10px #000000",
                                height: "50px",
                                width: "100%",
                                "text-align": "center",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                "margin-top": "-25px",
                                "margin-left": "-50%"}}>
                                {project.title}
                              </h3>
                            </>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </TabPane>
                )}
              </TabContent>
            </Row>

            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <h3 className="title">References</h3>
                <div style={{display: "flex", "justify-content": "center"}}>
                  {data.references.map(ref =>
                      <div style={{"min-width": "20%", "padding": "10px"}}>
                        <div className="title" style={{margin: 10, padding: 0}}>
                          {ref.title}
                        </div>
                        <div className="description">
                          {ref.description}
                        </div>
                      </div>
                    )}
                    </div>
              </Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ProfilePage;
