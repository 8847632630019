import React from "react";

// reactstrap components
import {
  Button,
  Input,
  Label,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,  
  Row,
  Col,
} from "reactstrap"; 

function Freetext(props) {
  let pageHeader = React.createRef();   

  return (
    <>
        <Input type="textarea" className="textarea" />
    </>
  );
}

export default Freetext;
