import React from "react";
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import FaqPageHeader from "components/Headers/FaqPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function ProfilePage() {

  const [pills, setPills] = React.useState("2");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <FaqPageHeader />
        <div className="section">
          <Container>
            
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <h3 className="title">Frage 1</h3>
                <h5 className="description" style={{color: "#eeeeee", "font-style": "italic"}}>
                  Antwort 1
                </h5>
              </Col>
            </Row>
            
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <h3 className="title">Frage 2</h3>
                <h5 className="description" style={{color: "#eeeeee", "font-style": "italic"}}>
                  Antwort 2
                </h5>
              </Col>
            </Row>

          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ProfilePage;
