import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function ProfilePageHeader(props) {
  let pageHeader = React.createRef();

  const name = props.data.name;
  const zip = props.data.zip;
  const city = props.data.city;
  const type = props.data.type;

  const design = props.design;
  const stats = props.stats;

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div
        className="page-header clear-filter page-header-small"
        style={{"height": "85vh", "max-height": "700px", "min-height": "550px"}}
        filter-color="blue"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/" + design.background) + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="photo-container" style={{width: 130, height: 130}}>
            <img alt="..." src={require("assets/img/" + design.profile)}></img>
          </div>
          <h3 className="title">
            <div style={{fontSize: 28}}>{name}</div>
            <div style={{fontSize: 24}}>#{zip}</div>
            <div style={{fontSize: 12, "margin-top": -15}}>{city}</div>
	        </h3>
          <p className="category" style={{fontSize: 28}}>{type}</p>
          <div className="content">
            <div className="social-description" style={{"max-width": "32%"}}>
              <h2>{stats.projects}</h2>
              <p>Projects</p>
            </div>
            <div className="social-description" style={{"max-width": "32%"}}>
              <h2>{stats.connections}</h2>
              <p>Connections</p>
            </div>
            <div className="social-description" style={{"max-width": "32%"}}>
              <h2>{stats.releases}</h2>
              <p>Releases</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ProfilePageHeader;
