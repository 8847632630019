/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import useGlobalStorage from '../../modules/GlobalState';

function DefaultFooter() {

  const useStorage = useGlobalStorage({
    storageOptions: { name: 'accounts' }
  });

  const [token, setToken] = useStorage('token');

  return (
    <>
      <footer className="footer footer-default" style={{backgroundColor: "black", color: "white"}}>
        <Container>
          <nav>
            <ul> 
              <li>
                <a
                  href="/"
		   style={{color: "white"}}
                >
                  Über uns
                </a>
              </li> 
              <li>
                <a
                  href="/faq-page" 
		 style={{color: "white"}}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;FAQ &amp; Features
                </a>
              </li> 
              { !token ? 
              <li>
                <a
                  href="/login-page" 
		 style={{color: "white"}}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Login
                </a>
              </li> 
              : <></>}
            </ul>
          </nav> 
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
