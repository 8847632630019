import React from "react";

// reactstrap components
import {
Radio,
RadioButtons,
Button,
Card,
CardHeader,
CardBody,
CardFooter,
Form,
Label,
Input,
InputGroupAddon,
InputGroupText,
InputGroup,
Container, 
Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";

function GuestsPage() {
const [firstFocus, setFirstFocus] = React.useState(false);
const [lastFocus, setLastFocus] = React.useState(false);
React.useEffect(() => {
  document.body.classList.add("login-page");
  document.body.classList.add("sidebar-collapse");
  document.documentElement.classList.remove("nav-open");
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  return function cleanup() {
    document.body.classList.remove("login-page");
    document.body.classList.remove("sidebar-collapse");
  };
}, []);
return (
  <>

    <ExamplesNavbar />
    <div className="page-header clear-filter" filter-color="blue">
      <div
        className="page-header-image"
        style={{
          backgroundImage: "url(" + require("assets/img/future.jpeg") + ")",
        }}
      ></div>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" md="4">
            <Card className="card-login card-plain">
              <Form action="" className="form" method="">
                <CardHeader className="text-center">
                  <div className="big-logo-container">
                    <img
                      alt="..."
                      src={require("assets/img/logodb.png")}
                    ></img>
                  </div>
                </CardHeader>
                <CardBody>

                <InputGroup
                  className={
                    "no-border input-lg" +
                    (firstFocus ? " input-group-focus" : "")
                  }
                >

                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons users_circle-08"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Event-Name"
                  type="text"
                  onFocus={() => setFirstFocus(true)}
                  onBlur={() => setFirstFocus(false)}
                ></Input>
              </InputGroup>

                <InputGroup
                  className={
                    "no-border input-lg" +
                    (firstFocus ? " input-group-focus" : "")
                  }
                >

                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons text_caps-small"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Ort des Geschehens"
                  type="text"
                  onFocus={() => setFirstFocus(true)}
                  onBlur={() => setFirstFocus(false)}
                ></Input>
              </InputGroup> 

                <InputGroup
                className={
                  "no-border input-lg" +
                  (firstFocus ? " input-group-focus" : "")
                }
                >

                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons text_caps-small"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Datum"
                    type="Date"


                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>


                <InputGroup
                className={
                  "no-border input-lg" +
                  (firstFocus ? " input-group-focus" : "")
                }
                >

                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons text_caps-small"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Beschreibung"
                    type="textarea"


                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>



                                </CardBody>
                                <CardFooter className="text-center">
                                  <Button
                                    block
                                    className="btn-round"
                                    color="info"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                    size="lg"
                                  >
                                    Veranstaltung erstellen
                                  </Button>

                                </CardFooter>
                              </Form>
                            </Card>
                          </Col>
                        </Container>
                      </div>
                      <TransparentFooter />
                    </div>
                  </>
                );
                }

                export default GuestsPage;
